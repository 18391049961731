import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Rapid thermal processing tools`}</h4>
    <p>{`Rapid thermal processing (RTP) is critical to several steps in chip manufacturing. RTP tools include lamps, lasers, or other mechanisms to quickly increase the temperature of a wafer in order to change its properties. For instance, RTP tools are used to activate materials (such as dopants in transistors) to change their properties, modify materials, or make deposited films denser to improve their properties. Some RTP tools provide heat for as long as multiple seconds (solely produced by the United States and South Korea); others provide it only for milliseconds (solely produced by the United States and Japan).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      